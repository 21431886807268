@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius; }

@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius; }

@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius; }

@mixin transition($definition) {
  -moz-transition: $definition;
  -o-transition: $definition;
  -webkit-transition: $definition;
  transition: $definition; }

@mixin gradient-top($color-a, $color-b) {
  background: $color-b /* Old browsers */;
  background: -moz-linear-gradient(top, $color-a 0%, $color-b 100%) /* FF3.6+ */;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$color-a), color-stop(100%,$color-b)) /* Chrome,Safari4+ */;
  background: -webkit-linear-gradient(top, $color-a 0%,$color-b 100%) /* Chrome10+,Safari5.1+ */;
  background: -o-linear-gradient(top, $color-a 0%,$color-b 100%) /* Opera 11.10+ */;
  background: -ms-linear-gradient(top, $color-a 0%,$color-b 100%) /* IE10+ */;
  background: linear-gradient(top, $color-a 0%,$color-b 100%) /* W3C */; }

@mixin box-shadow($x, $y, $blur, $spread, $color) {
  -webkit-box-shadow: $x $y $blur $spread $color;
  -moz-box-shadow: $x $y $blur $spread $color;
  box-shadow: $x $y $blur $spread $color; }

@mixin box-shadow-inset($x, $y, $blur, $spread, $color) {
  -webkit-box-shadow: inset $x $y $blur $spread $color;
  -moz-box-shadow: inset $x $y $blur $spread $color;
  box-shadow: inset $x $y $blur $spread $color; }

@mixin box-shadow-both($x, $y, $blur, $spread, $color, $i_x, $i_y, $i_blur, $i_spread, $i_color) {
  -webkit-box-shadow: $x $y $blur $spread $color, inset $i_x $i_y $i_blur $i_spread $i_color;
  -moz-box-shadow: $x $y $blur $spread $color, inset $i_x $i_y $i_blur $i_spread $i_color;
  box-shadow: $x $y $blur $spread $color, inset $i_x $i_y $i_blur $i_spread $i_color; }

@mixin transform($t) {
  -moz-transform: $t;
  -webkit-transform: $t;
  -o-transform: $t;
  -ms-transform: $t;
  transform: $t; }
