$base-color: #131313;
$background: #fff;

$blue: #0081ff;
$rose: #cb0c39;
$light-gray: #9a9a9a;
$gray: #595959;

$top-bg: #f2f2f2;
$article-text-color: #666666;
$article-border-bottom: #e7e7e7;

