@import 'colors.sass';

body {
  color: $base-color;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5; }

h1, h2 {
  font-weight: 400; }

h1 {
  font-size: 40px;
  color: $rose;
  margin-top: 50px;
  margin-bottom: 0; }

h2 {
  font-size: 30px;
  font-weight: 400; }

h4 {
  font-size: 1em;
  font-weight: 700;
  margin: 0 0 .5em; }

p {
  margin-bottom: 1.3em; }

@media screen and (max-width: 600px) {
  h1 {
    margin-top: 0px; } }
