@import 'colors.sass';
@import 'mixins.sass';

* {
  margin: 0;
  padding: 0; }

@import 'typography.sass';

body, html {
  height: 100%; }
body {
  background: $background;
  font-family: 'Roboto Slab', georgia, times, serif; }

img {
  border: 0; }

.center-wrapper {
  width: 700px;
  margin: 0 auto; }

#wrapper {
  width: 100%;
  position: relative;
  min-height: 100%;
  padding-bottom: 60px; }

#header a.back .arrow .blue, #header a.back .arrow .gray, .article .article-footer .arrow, .article .article-footer .comments:before, .pagination .next:after, .pagination .next:before, .pagination .prev:after, .pagination .prev:before, .shop .icon:before, .shop .icon:after {
  background: url('/images/sprites.png') no-repeat; }

#header {
  width: 100%;

  &.top-line, .top-line {
    height: 10px;
    width: 100%;
    background: $top-bg;
    text-align: center; }

  &.top-line {
    height: 50px;
    background: none; }

  a.logo {
    display: block;
    margin: 60px auto 20px;
    &, img {
      height: 50px;
      width: 324px; }

    &.small {
      background: none;
      width: 216px;
      height: 33px;
      margin: 8px auto 9px;
      position: relative;

      .blue, .gray {
        width: 216px;
        height: 33px;
        position: absolute;
        top: 5px;
        left: 0;
        @include transition(opacity 300ms ease); }

      .blue {
        opacity: 0; }

      &:hover {
        .blue {
          opacity: 1; }
        .gray {
          opacity: 0; } } } }

  a.back {
    position: absolute;
    left: 10px;
    top: 15px;
    display: block;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: $light-gray;
    text-decoration: none;
    @include transition(color 300ms ease);

    .arrow {
      height: 33px;
      width: 18px;
      margin: 8px 0 9px;
      position: relative;
      display: inline-block;
      vertical-align: bottom;
      margin-right: 10px;

      .blue, .gray {
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 33px;
        @include transition(opacity 300ms ease); }

      .blue {
        background-position: -18px -50px;
        opacity: 0; }

      .gray {
        background-position: 0 -50px; } }

    &:hover {
      color: $blue;

      .blue {
        opacity: 1; }

      .gray {
        opacity: 0; } } }

  .top-nav {
    margin: 20px auto;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin-left: 20px;

      &:first-child {
        margin-left: 0; }

      a {
        display: block;
        font-size: .8em;
        text-decoration: none;
        color: $gray;
        @include transition(all 300ms ease);

        &:hover {
          color: $blue; } }

      &.highlight {
        a {
          padding: .5em 1em;
          border: 1px solid $gray;
          @include border-radius(4px);

          &:hover {
            border-color: $blue; } } } } }

  &.top-line .top-nav {
    position: absolute;
    right: 20px;
    top: 0;
    height: 50px;
    line-height: 50px; } }

#content {
  width: 700px;
  margin: 50px auto 0;

  img, iframe {
    width: 100%; }

  .date {
    margin-top: -10px;
    color: $light-gray;
    font-size: 13px;
    margin-bottom: 20px; }

  a {
    color: $base-color;
    @include transition(all 300ms ease);

    &:hover {
      color: $blue; } }

  .article {
    position: relative;
    padding-bottom: 20px;
    a {
      display: block;
      text-decoration: none;
      color: $article-text-color;
      font-size: 18px;
      font-weight: 300;
      overflow: hidden;

      &, h2 {
        @include transition(color 200ms ease); }

      h2 {
        color: $gray;
        font-size: 30px;
        font-weight: 400; } }

    .article-footer {
      height: 55px;
      border-bottom: 1px solid $article-border-bottom;
      background: url('/images/article-footer-bg.png') repeat-x top left;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 13px;
      @include transition(height 200ms ease);

      .shadow {
        position: absolute;
        height: 5px;
        left: 0;
        right: 0;
        top: -5px;
        background: url('/images/article-footer-shadow.png') no-repeat center top; }

      .date, .read-more {
        @include transition(opacity 200ms ease); }

      .comments, .date {
        color: $article-text-color;
        margin-top: 17px; }
      a.comments {
        float: left;
        font-size: 13px;
        padding-bottom: 0;
        @include transition(margin 200ms ease);

        &:before {
          content: '';
          display: inline-block;
          width: 17px;
          height: 17px;
          background-position: -254px -50px;
          margin-right: 5px;
          vertical-align: middle; } }

      .date {
        float: right;
        margin-bottom: 0; }

      .read-more {
        font-size: 30px;
        font-weight: 400;
        color: $blue;
        position: absolute;
        top: 0;
        right: 0;
        line-height: 85px;
        opacity: 0;

        .arrow {
          display: inline-block;
          height: 21px;
          width: 12px;
          margin-right: 15px;
          background-position: -12px -83px; } } }

    &:hover {
      a {
        color: $base-color;

        h2 {
          color: $rose; } }

      .article-footer {
        height: 85px;

        .read-more {
          opacity: 1; }

        .date {
          opacity: 0; }

        .comments {
          margin-top: 32px; } } } }

  .credits {
    margin-top: 2em;

    .shops {
      float: right; }
    .shop {
      display: inline-block;
      margin-left: .5em;
      color: $base-color;
      text-decoration: none;
      @include transition(all 300ms ease);

      .icon {
        width: 26px;
        height: 26px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        &:before, &:after {
          position: absolute;
          display: block;
          content: '';
          width: 26px;
          height: 26px;
          @include transition(all 300ms ease); }

        &:after {
          opacity: 0; } }

      &:hover {
        color: $blue;
        .icon {
          &:before {
            opacity: 0; }
          &:after {
            opacity: 1; } } } }

    .steam {
      .icon {
        &:before {
          background-position: -253px -109px; }
        &:after {
          background-position: -279px -109px; } } }

    .humblebundle {
      .icon {
        &:before {
          background-position: -253px -83px; }
        &:after {
          background-position: -279px -83px; } } } } }


#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: $top-bg;
  line-height: 50px;
  color: $light-gray;
  text-align: center;
  font-size: 13px; }


#disqus_thread {
  margin: 50px 0; }

.pagination {
  padding: 40px 0;
  height: 30px;
  position: relative;
  .next, .prev {
    display: block;
    position: absolute;
    width: 40px;
    height: 41px;
    &:after, &:before {
      display: block;
      position: absolute;
      content: '';
      width: 18px;
      height: 33px;
      margin: 4px 11px;
      @include transition(all 300ms ease); }

    &:after {
      opacity: 0; }
    &:hover {
      &:after {
        opacity: 1; }
      &:before {
        opacity: 0; } } }

  .prev {
    left: 10px;
    &:before {
      background-position: 0px -50px; }
    &:after {
      background-position: -18px -50px; } }

  .next {
    right: 10px;
    &:before {
      background-position: -290px -50px; }
    &:after {
      background-position: -272px -50px; } } }

@media screen and (max-width: 864px) {
  #header {
    a.back {
      display: none; }

    &.top-line {
      .top-nav {
        right: 0;
        position: relative;
        margin: 10px auto 20px;
        text-align: center;
        height: auto;
        line-height: 1.5; } } }

  #content {
    width: auto;
    padding: 0 20px;
    .article {
      width: auto;

      .article-footer {
        width: 100%; } } } }

@media screen and (max-width: 600px) {
  #header {
    margin-bottom: 50px; }
  #header.top-line {
    height: auto;
    margin-bottom: 20px; }

  #content {
    margin-top: 0px; } }

@media screen and (max-width: 400px) {
  #header {
    a.logo {
      &, img {
        width: 300px;
        height: 45px; }

      &.small {
        float: right;
        margin: 7px 10px;
        &, .blue, .gray {
          width: 200px;
          height: auto; } } } }
  #content {
    padding: 0 10px; } }

@media screen and (max-width: 320px) {
  #header {
    a.logo {
      &, img {
        width: 250px;
        height: auto; }
      &.small {
        margin: 10px 10px;
        &, .blue, .gray {
          width: 180px; } } } } }
@media screen and (max-width: 270px) {
  #header {
    a.logo {
      &, img {
        width: 200px; }
      &.small {
        margin: 12px 10px;
        &, .blue, .gray {
          width: 150px; } } } } }
